import { useState, useEffect } from 'react';
import { Common } from '@stockbit/ui';
import Link from 'next/link';
import { assetPrefix } from 'constants/app';
import Image from 'global/Image';
import clsx from 'clsx';
import ReactVisibilitySensor from 'react-visibility-sensor';
import useDownloadDesktopApp from 'hooks/useDownloadDesktopApp';
import {
  Title,
  Subtitle,
  AnimatedSubtitle,
  SubtitleWrapper,
  TitleWrapper,
  ButtonInvest,
  HeaderWrapper,
  AnimationWrapper,
  Illustration,
  StoreImage,
} from './styled';
import { PhonePreview } from '../uikit';

const { Flex } = Common;

function Header() {
  const dymText = ['Komunitas', 'Teman', 'Keluarga'];

  const [viewed, setViewed] = useState(false);
  const [indexActive, setIndexActive] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndexActive((state) => (state + 1) % dymText.length);
    }, 3000);

    return () => clearInterval(interval);
  });

  const { getUrl } = useDownloadDesktopApp();

  return (
    <ReactVisibilitySensor
      offset={{ top: 140, bottom: 140 }}
      partialVisibility
      onChange={(v) => setViewed((s) => s || v)}
    >
      <HeaderWrapper>
        <TitleWrapper>
          <Title>Investasi Saham Bersama</Title>
          <SubtitleWrapper>
            {dymText.map((text, index) => (
              <AnimatedSubtitle
                key={text}
                className={indexActive === index ? 'active' : ''}
              >
                {text}
              </AnimatedSubtitle>
            ))}
          </SubtitleWrapper>
          <Subtitle>
            Stockbit adalah aplikasi untuk kamu yang ingin berdiskusi, analisa
            dan investasi saham dalam satu tempat.
          </Subtitle>
          <Link href="/register" passHref>
            <ButtonInvest>Mulai berinvestasi</ButtonInvest>
          </Link>
          <Flex>
            <a
              target="_blank"
              href="https://itunes.apple.com/us/app/stockbit/id1184800207?mt=8"
              rel="noopener noreferrer"
            >
              <StoreImage
                width={120}
                alt="appstore"
                src="/images/appstore-logo.png"
              />
            </a>
            <a
              target="_blank"
              href="https://play.google.com/store/apps/details?id=com.stockbit.android&amp;hl=en"
              rel="noopener noreferrer"
            >
              <StoreImage
                width={120}
                src="/images/playstore-logo.png"
                alt="playstore"
              />
            </a>
            <a target="_blank" href={getUrl('macos')} rel="noopener noreferrer">
              <StoreImage
                width={120}
                src="/images/download-macos.svg"
                alt="playstore"
              />
            </a>
            <a
              target="_blank"
              href={getUrl('windows')}
              rel="noopener noreferrer"
            >
              <StoreImage
                width={120}
                src="/images/download-windows.svg"
                alt="playstore"
              />
            </a>
          </Flex>
          <div style={{ marginTop: 60 }}>
            <Flex align="center">
              <Image
                alt="Logo Otoritas Jasa Keuangan"
                src="/images/pt-stockbit-sekuritas.svg"
                style={{ marginRight: 24 }}
              />
              <Image
                src="/images/idx.svg"
                alt="Logo Indonesia Stock Exchange"
              />
            </Flex>
          </div>
        </TitleWrapper>
        <AnimationWrapper>
          <div
            className={clsx('fadeInX', viewed && 'fadeIn')}
            style={{ position: 'relative' }}
          >
            <div style={{ zIndex: 1, position: 'relative' }}>
              <PhonePreview
                videoUrl={`${assetPrefix}/homepage/depan2.mp4`}
                fallbackImage="/images/depan2fallback.webp"
              />
            </div>
            <Illustration src="/images/kartun.svg" alt="kartun" />
          </div>
        </AnimationWrapper>
      </HeaderWrapper>
    </ReactVisibilitySensor>
  );
}

export default Header;
